import { createRoot } from 'react-dom/client';
import { StrictMode, lazy, Suspense, useReducer, useState } from 'react';
import { KcContext, kcContext as kcLoginThemeContext } from './keycloak-theme/login/kcContext';
import { kcContext as kcAccountThemeContext } from './keycloak-theme/account/kcContext';
import { ForceUpdateContext } from './keycloak-theme/login/context/ForceUpdateContext';

const Wrapper = () => {
  const [key, forceUpdate] = useReducer((x) => x + 1, 0);

  const [userLogin, setUserLogin] = useState('');
  const [userPassword, setUserPassword] = useState('');

  return (
    <StrictMode>
      <ForceUpdateContext.Provider value={{ forceUpdate }}>
        <Suspense>
          {(() => {
            if (kcLoginThemeContext !== undefined) {
              return (
                <KcLoginThemeApp
                  userLogin={userLogin}
                  userPassword={userPassword}
                  setUserLogin={setUserLogin}
                  setUserPassword={setUserPassword}
                  key={key}
                  kcContext={kcLoginThemeContext as KcContext}
                />
              );
            }

            if (kcAccountThemeContext !== undefined) {
              return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
            }

            return <App />;
          })()}
        </Suspense>
      </ForceUpdateContext.Provider>
    </StrictMode>
  );
};

const KcLoginThemeApp = lazy(() => import('./keycloak-theme/login/KcApp'));
const KcAccountThemeApp = lazy(() => import('./keycloak-theme/account/KcApp'));
const App = lazy(() => import('./App'));

const rootElement = document.getElementById('root')
if (rootElement) {
  rootElement.classList.add('theme_version');
  rootElement.classList.add(String(process.env.REACT_APP_KC_THEME_VERSION));
}

createRoot(document.getElementById('root')!).render(<Wrapper />);
